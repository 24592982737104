import { useToggle } from '@ravnur/hooks/useToggle';
import { defineComponent, watch } from 'vue';
import { useRoute } from 'vue-router';

import AppFooter from '@/components/app-footer/app-footer';
import AppHeader from '@/components/app-header/app-header';
import AppNav from '@/components/app-nav/app-nav';
import Breadcrumbs from '@/components/breadcrumbs/breadcrumbs';
import Notifications from '@/components/notifications/notifications';
import AbuseModule from '@/modules/abuse/abuse-module';
import CoreModule from '@/modules/core/core-module';
import LiveModule from '@/modules/live/live-module';
import AppContent from '@/pages/admin/app-content';
import JobPullingManager from '@/pages/admin/job-pulling-manager';
import PopupManager from '@/pages/admin/popup-manager';
import MetadataModule from '@/modules/metadata/metadata-module';
import usePrompt from '@ravnur/hooks/usePrompt';
import useUploadStore from '@/store/upload';

const CN = 'ravnur-admin';

export default defineComponent({
  setup() {
    const [isExpanded, toggleExpanded] = useToggle(true);
    const [isPushed, togglePushed] = useToggle(false);
    const route = useRoute();
    const uploadStore = useUploadStore();

    usePrompt(() => uploadStore.isProcessing);

    watch(
      () => route.name,
      () => togglePushed(false)
    );

    return () => (
      <div class={`${CN}__portal`}>
        <AppHeader toggleApplicationMenu={togglePushed} />
        <div class="app-body">
          <AppNav
            isExpanded={isExpanded.value}
            isPushed={isPushed.value}
            toggleExpanded={toggleExpanded}
            onClose={togglePushed}
          />
          <Breadcrumbs isExpandedNav={isExpanded.value} />
          <AppContent isExpandedNav={isExpanded.value} />
        </div>
        <AppFooter isExpandedNav={isExpanded.value} />
        <Notifications />
        <PopupManager />
        <JobPullingManager />

        <CoreModule />
        <LiveModule />
        <AbuseModule />
        <MetadataModule />
      </div>
    );
  },
});
