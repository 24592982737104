import { computed, defineComponent, PropType, ref } from 'vue';
import AppNavItem from '../app-nav-item/app-nav-item';
import { MENU } from '@/entity-route-config';
import useSecurityStore from '@/store/security';
import useRootStore from '@/store/root';
import { ApplicationTypes } from '@ravnur/shared/types/Application';
import { detectCookieName } from '@ravnur/auth/helpers/detect-cookie-name';

import './app-nav.scss';

const CN = 'app-nav';

export default defineComponent({
  props: {
    isExpanded: { type: Boolean, required: true },
    isPushed: { type: Boolean, required: true },
    toggleExpanded: { type: Function as PropType<() => void>, required: true },
  },
  setup(props, { emit }) {
    const security = useSecurityStore();
    const store = useRootStore();

    const menu = ref(MENU);

    const handleClose = () => emit('close');

    const myAccountUrl = computed(() => {
      const { applications } = security;
      const myAccountApp = applications?.find(
        (app) => app.applicationType === ApplicationTypes.MY_ACCOUNT
      );
      return myAccountApp?.portalUrl ?? '';
    });

    const toMyAccount = () => {
      const cn = detectCookieName();
      location.replace(`${myAccountUrl.value}?cn=${cn}`);
    };

    const isRootApplication = security.isRootApplication;
    const isPublicApplication = security.isPublicApplication;

    return () => {
      const cn = {
        [CN]: true,
        [`${CN}--desktop`]: true,
        [`${CN}--collapsed`]: !props.isExpanded,
        [`${CN}--pushed`]: props.isPushed,
      };
      const collapseButtonTitle = props.isExpanded ? 'Collapse menu' : 'Expand menu';
      return (
        <nav class={cn}>
          <scrollable ref="scroll" class={`${CN}__scroll`}>
            <r-button
              class={`${CN}__close`}
              icon="close"
              mode="frameless"
              onClick={() => handleClose()}
            />
            <ul class={`${CN}__menu`} id="app-navigations">
              {menu.value.map((item) => (
                <AppNavItem key={item.tkey} item={item} />
              ))}
            </ul>
            <ul class={`${CN}__mobile-menu`}>
              <li v-show={!isRootApplication && !isPublicApplication} onClick={toMyAccount}>
                <icon type="user" />
                <l10n group="common" tkey="header__my-account" />
              </li>
              <li onClick={() => store.logout()}>
                <icon type="logout" />
                <l10n group="common" tkey="header__logout" />
              </li>
            </ul>
          </scrollable>

          <r-button
            class={`${CN}__minimizer`}
            icon="arrow-left"
            mode="frameless"
            onclick={props.toggleExpanded}
            size="lg"
            title={collapseButtonTitle}
          />
        </nav>
      );
    };
  },
});
